/*
 * Matching Messages
 *
 * This contains all the text for the Matching container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Matching';

export default defineMessages({
  header: {
    defaultMessage: 'Matching',
  },
  careProviderMatching: {
    defaultMessage: 'Care Provider Matching',
  },
  welcomeToInkblot: {
    defaultMessage:
      'Hi {name}! Welcome to Inkblot! This matching process will help you find your care provider.',
  },
  weMatchBased: {
    defaultMessage:
      'We match based on your needs and preferences. We will generate a shortlist of care providers that we have found to be most effective for your profile.',
  },
  youWillOnly: {
    defaultMessage:
      'You will only need to go through this process once. It will take a few minutes.',
  },
  allInformation: {
    defaultMessage:
      'All information you provide during matching is strictly confidential. We do use anonymous data in reports and to improve our service. No identifiable information is ever used or shared.',
  },
  couplesPriceNote: {
    defaultMessage:
      // eslint-disable-next-line no-template-curly-in-string
      'Video sessions for couples counselling are ${videoCouplesRate}/hr after sponsored sessions (if any). Initial consultations are not available for couples counselling.',
  },
  couplesPriceNoteEmployee: {
    defaultMessage:
      // eslint-disable-next-line no-template-curly-in-string
      'Video sessions for couples counselling are ${videoCouplesRate}/hr after sponsored sessions (if any). Initial consultations are not available for couples counselling.',
  },
  getStarted: {
    defaultMessage: 'Get Started!',
  },
  couplesProviderMatching: {
    defaultMessage: 'Couples Care Provider Matching',
  },
  welcomeCouples: {
    defaultMessage:
      'Hi {name}! This matching process will help you and your partner find your couples care provider.',
  },
  invitePartner: {
    defaultMessage:
      'You will invite your partner after you have selected your care provider.',
  },
  selectMatchingPath: {
    defaultMessage: 'Select a Matching Path',
  },
  express: { defaultMessage: 'Express' },
  average7Minutes: {
    defaultMessage: 'Average 7 minutes',
  },
  mostPopular: {
    defaultMessage: 'Most popular',
  },
  generalEvaluation: {
    defaultMessage: 'General evaluation of your needs and preferences',
  },
  comprehensive: {
    defaultMessage: 'Comprehensive',
  },
  average12Minutes: {
    defaultMessage: 'Average 12 minutes',
  },
  mostAccurate: {
    defaultMessage: 'Most accurate',
  },
  detailedEvaluation: {
    defaultMessage: 'Detailed evaluation of your needs and preferences',
  },
  select: { defaultMessage: 'Select' },
  startingMatch: {
    defaultMessage: 'Starting Match...',
  },
  youreBack: {
    defaultMessage: 'Welcome to matching!',
  },
  weNoticed: {
    defaultMessage:
      'You have either just finished an assessment, or have partially completed the matching process.',
  },
  continueFrom: {
    defaultMessage: 'Continue with matching',
  },
  startFresh: {
    defaultMessage: 'Change my previous answers',
  },
  perfectPractitioner: {
    defaultMessage: 'Your perfect care provider is waiting for you!',
  },
  exitMatching: {
    defaultMessage: 'Are you sure you want to exit the matching process?',
  },
  continueMatching: {
    defaultMessage: 'No, continue matching',
  },
  enterAnEmailWithExample: {
    defaultMessage: 'Please enter an email address - Example, user@example.com',
  },
  exit: {
    defaultMessage: 'Exit',
  },
  evaluation: {
    defaultMessage: 'Evaluation',
  },
  pastTwoWeeks: {
    defaultMessage: 'In the past two weeks:',
  },
  individually: {
    defaultMessage: 'Individually',
  },
  interpersonally: {
    defaultMessage: 'Interpersonally',
  },
  socially: {
    defaultMessage: 'Socially',
  },
  overall: {
    defaultMessage: 'Overall',
  },
  wellBeing: {
    defaultMessage: 'Personal well-being',
  },
  familyRelationship: {
    defaultMessage: 'Family, close relationships',
  },
  couplesTrust: {
    defaultMessage: 'How much trust has there been in your relationship?',
  },
  couplesConflict: {
    defaultMessage:
      'How well has conflict been dealt with in your relationship?',
  },
  couplesValue: {
    defaultMessage:
      'How well have your values and goals aligned with your partner?',
  },
  couplesHappy: {
    defaultMessage: 'How happy have you been in your relationship?',
  },
  workSchool: {
    defaultMessage: 'Work, school',
  },
  viewMatches: {
    defaultMessage: 'View Matches',
  },
  next: {
    defaultMessage: 'Next',
  },
  previous: {
    defaultMessage: 'Previous',
  },
  dassHowOften: {
    defaultMessage: 'In the last two weeks how often did you:',
  },
  dass1: {
    defaultMessage: 'Feel you had nothing to look forward to?',
  },
  dass2: {
    defaultMessage: 'Feel scared without any good reason?',
  },
  dass3: {
    defaultMessage: 'Find it hard to wind down?',
  },
  dass4: {
    defaultMessage: 'Feel guilty about substance use (alcohol, drugs, etc)?',
  },
  dass5: {
    defaultMessage: 'Feel that life was meaningless?',
  },
  dass6: {
    defaultMessage: 'Feel you were close to panic?',
  },
  dass7: {
    defaultMessage:
      'Feel intolerant of anything that kept you from what you were doing?',
  },
  dassOfTheTime: {
    defaultMessage: 'of the time',
  },
  dassNone: {
    defaultMessage: 'None',
  },
  dassSome: {
    defaultMessage: 'Some',
  },
  dassGoodPart: {
    defaultMessage: 'Good part',
  },
  dassMost: {
    defaultMessage: 'Most',
  },
  selectAny: {
    defaultMessage: 'Select any statements that apply to you:',
  },
  feelFreeSkip: {
    defaultMessage:
      'Feel free to skip this section if none apply. You can choose multiple options.',
  },
  gen1: {
    defaultMessage: 'I am feeling stuck',
  },
  gen2: {
    defaultMessage: 'I have difficulty planning',
  },
  gen3: {
    defaultMessage: 'I doubt myself',
  },
  gen4: {
    defaultMessage: 'I am susceptible to stress',
  },
  gen5: {
    defaultMessage: 'I am excessively fatigued',
  },
  diagnosisTitle: {
    defaultMessage: 'Specific Diagnosis',
  },
  diagnosisQuestion: {
    defaultMessage:
      'Have you been formally diagnosed by a healthcare provider with any of the following?',
  },
  diagnosis1: {
    defaultMessage: 'Behavioural and/or Substance Addictions',
  },
  diagnosis2: {
    defaultMessage: 'Attention-Deficit Hyperactivity Disorder (ADHD)',
  },
  diagnosis3: {
    defaultMessage: 'Bipolar Disorder',
  },
  diagnosis4: {
    defaultMessage: 'Borderline Personality Disorder',
  },
  diagnosis5: {
    defaultMessage: 'Eating Disorder',
  },
  diagnosis6: {
    defaultMessage: 'Obsessive Compulsive Disorder (OCD)',
  },
  diagnosis7: {
    defaultMessage: 'Post-Partum Depression / Anxiety',
  },
  diagnosis8: {
    defaultMessage: 'PTSD',
  },
  diagnosis9: {
    defaultMessage: 'Sexual Disorder',
  },
  diagnosis10: {
    defaultMessage: 'Depressive Disorder',
  },
  diagnosis11: {
    defaultMessage: 'Anxiety Disorder',
  },
  diagnosis12: {
    defaultMessage: 'Sleep Disorder',
  },
  diagnosis13: {
    defaultMessage: 'Gambling Disorder',
  },
  diagnosis14: {
    defaultMessage: 'Narcissistic Personality Disorder',
  },
  phys1: {
    defaultMessage: 'I am sleeping poorly',
  },
  phys2: {
    defaultMessage: 'I have longstanding physical pain',
  },
  phys3: {
    defaultMessage: 'I have difficulty remembering things',
  },
  phys4: {
    defaultMessage: 'I am overweight or underweight',
  },
  phys5: {
    defaultMessage: 'I have a prescribed psychiatric medication',
  },
  dep1: {
    defaultMessage: 'I often have negative thoughts',
  },
  dep2: {
    defaultMessage: 'I have excessive guilt',
  },
  dep3: {
    defaultMessage: 'I am excessively angry',
  },
  dep4: {
    defaultMessage: 'I am having thoughts of suicide',
  },
  ptsd1: {
    defaultMessage: 'I have a history of trauma',
  },
  ptsd2: {
    defaultMessage: 'I am having flashbacks',
  },
  ptsd3: {
    defaultMessage: 'I am having nightmares',
  },
  ptsd4: {
    defaultMessage: 'I have poor self esteem',
  },
  ptsd5: {
    defaultMessage: 'I am feeling isolated',
  },
  sub1: {
    defaultMessage: 'I drink/use too much or too often',
  },
  sub2: {
    defaultMessage: 'I am having frequent cravings',
  },
  sub3: {
    defaultMessage: 'I have lots of guilt',
  },
  sub4: {
    defaultMessage: 'I am unable to stop once started',
  },
  sub5: {
    defaultMessage: 'Others have been concerned',
  },
  stressTitle: {
    defaultMessage: 'Specific Stressors',
  },
  stressQuestion: {
    defaultMessage: 'Are any of these factors causing distress?',
  },
  stress1: {
    defaultMessage: 'Loneliness',
  },
  stress2: {
    defaultMessage: 'Marital/relationships',
  },
  stress3: {
    defaultMessage: 'Family dynamics',
  },
  stress4: {
    defaultMessage: 'Grief & loss',
  },
  stress5: {
    defaultMessage: 'Caregiver burnout',
  },
  stress6: {
    defaultMessage: 'Injury or illness',
  },
  stress7: {
    defaultMessage: 'Gender identity',
  },
  stress8: {
    defaultMessage: 'Sexual orientation',
  },
  stress9: {
    defaultMessage: 'Being abused or neglected',
  },
  stress10: {
    defaultMessage: 'Financial',
  },
  stress11: {
    defaultMessage: 'Legal',
  },
  stress12: {
    defaultMessage: 'Children',
  },
  stress13: {
    defaultMessage: 'Unemployment',
  },
  stress14: {
    defaultMessage: 'Retirement',
  },
  stress15: {
    defaultMessage: 'Procrastination',
  },
  stress16: {
    defaultMessage: 'Perfectionism',
  },
  stress17: {
    defaultMessage: 'Sexual difficulties',
  },
  workTitle: {
    defaultMessage: 'Specific Stressors',
  },
  workQuestion: {
    defaultMessage:
      'Do any of these cause you distress in your main employment?',
  },
  work1: {
    defaultMessage: 'High workload',
  },
  work2: {
    defaultMessage: 'Lack of control',
  },
  work3: {
    defaultMessage: 'Not meaningful',
  },
  work4: {
    defaultMessage: 'Not appreciated',
  },
  work5: {
    defaultMessage: 'Not treated fairly',
  },
  work6: {
    defaultMessage: 'Poor management',
  },
  work7: {
    defaultMessage: 'High conflict',
  },
  work8: {
    defaultMessage: 'Job uncertainty',
  },
  work9: {
    defaultMessage: 'Poor work-life balance',
  },
  work10: {
    defaultMessage: 'Sexual harassment',
  },
  work11: {
    defaultMessage: 'Bullying',
  },
  work12: {
    defaultMessage: 'Racial discrimination',
  },
  work13: {
    defaultMessage: 'Gender discrimination',
  },
  schoolTitle: {
    defaultMessage: 'Specific Stressors',
  },
  schoolQuestion: {
    defaultMessage: 'Do any of these cause you distress at your school?',
  },
  school1: {
    defaultMessage: 'High workload',
  },
  school2: {
    defaultMessage: 'Difficulty adjusting',
  },
  school3: {
    defaultMessage: 'Difficulty socializing',
  },
  school4: {
    defaultMessage: 'High competition',
  },
  school5: {
    defaultMessage: 'Expenses',
  },
  school6: {
    defaultMessage: 'Future employment',
  },
  school7: {
    defaultMessage: 'Not treated fairly',
  },
  school8: {
    defaultMessage: 'Poor school-life balance',
  },
  school9: {
    defaultMessage: 'Sexual harassment',
  },
  school10: {
    defaultMessage: 'Bullying',
  },
  school11: {
    defaultMessage: 'Racial discrimination',
  },
  school12: {
    defaultMessage: 'Gender discrimination',
  },
  religionTitle: {
    defaultMessage: 'Preferences',
  },
  religionQuestion: {
    defaultMessage:
      'Do you prefer a counsellor with expertise in a specific religion or spirituality?',
  },
  religionCatholicism: {
    defaultMessage: 'Christianity: Catholicism',
  },
  religionProtestantism: {
    defaultMessage: 'Christianity: Protestantism',
  },
  religionChristianityOther: {
    defaultMessage: 'Christianity: Other',
  },
  religionJudaism: {
    defaultMessage: 'Judaism',
  },
  religionIslam: {
    defaultMessage: 'Islam',
  },
  religionHinduism: {
    defaultMessage: 'Hinduism',
  },
  religionBuddhism: {
    defaultMessage: 'Buddhism',
  },
  religionSikhism: {
    defaultMessage: 'Sikhism',
  },
  religionIndigenous: {
    defaultMessage: 'Indigenous Spiritualities',
  },
  religionNonreligious: {
    defaultMessage: 'Non-Religious Spirituality',
  },
  religionBahai: {
    defaultMessage: "Bahá'í Faith",
  },
  religionTaoism: {
    defaultMessage: 'Taoism',
  },
  religionShinto: {
    defaultMessage: 'Shinto',
  },
  religionFalunGong: {
    defaultMessage: 'Falun Gong',
  },
  religionJainism: {
    defaultMessage: 'Jainism',
  },
  religionAfrican: {
    defaultMessage: 'African Traditional Spiritualities/Religions',
  },
  religionAsian: {
    defaultMessage: 'Asian Traditional Spiritualities/Religions',
  },
  religionLandEarth: {
    defaultMessage: 'Land/Earth Based Spiritualities',
  },
  religionShamanism: {
    defaultMessage: 'Shamanism',
  },
  religionWiccan: {
    defaultMessage: 'Wiccan/Paganism',
  },
  religionAtheism: {
    defaultMessage: 'Atheism',
  },
  other: {
    defaultMessage: 'Other',
  },
  wsTitle: {
    defaultMessage: 'Specific Stressors',
  },
  wsQuestion: {
    defaultMessage:
      'Do you spend most of your time at work, as a student, or neither?',
  },
  wsWork: {
    defaultMessage: 'At work',
  },
  wsSchool: {
    defaultMessage: 'As a student',
  },
  wsEqual: {
    defaultMessage: 'Equally at work and as a student',
  },
  wsNeither: {
    defaultMessage: 'Neither',
  },
  wsizeTitle: {
    defaultMessage: 'Specific Stressors',
  },
  wsizeQuestion: {
    defaultMessage: 'How large is the company (# of employees):',
  },
  schoolsizeTitle: {
    defaultMessage: 'Specific Stressors',
  },
  schoolsizeQuestion: {
    defaultMessage: 'What is your current school level:',
  },
  schoolsizeKindergarten: {
    defaultMessage: 'Kindergarten-Grade 12',
  },
  schoolsizeCollege: {
    defaultMessage: 'College',
  },
  schoolsizeUniversity: {
    defaultMessage: 'University',
  },
  schoolsizeOther: {
    defaultMessage: 'Other',
  },
  rgoalTitle: {
    defaultMessage: 'Goals for Care',
  },
  rgoalQuestion: {
    defaultMessage: 'What are you looking to achieve?',
  },
  rgoal1: {
    defaultMessage: 'Communicate Better',
  },
  rgoal2: {
    defaultMessage: 'Increase trust',
  },
  rgoal3: {
    defaultMessage: 'Understand each other better',
  },
  rgoal4: {
    defaultMessage: 'Improve sex & intimacy',
  },
  rgoal5: {
    defaultMessage: 'Handle conflict better',
  },
  rgoal6: {
    defaultMessage: 'Prevent separation/divorce',
  },
  rgoal7: {
    defaultMessage: 'Work as a team',
  },
  rgoal8: {
    defaultMessage: 'Reduce tension',
  },
  rgoal9: {
    defaultMessage: 'Love each other again',
  },
  rgoal10: {
    defaultMessage: 'Issues around raising kids',
  },
  yesInsurance: {
    defaultMessage: 'Yes',
  },
  notSureInsurance: {
    defaultMessage: 'I am not sure',
  },
  noInsurance: {
    defaultMessage: 'No',
  },
  insurance: {
    defaultMessage: 'Benefits Coverage',
  },
  insuranceCompany: {
    defaultMessage:
      'What insurance company provides you with mental health benefits?',
  },
  insuranceCompanies: {
    defaultMessage: 'Insurance Companies',
  },
  almostInsurance: {
    defaultMessage:
      'Almost all insurance policies cover MSWs (Master of Social Work) and psychologists. There is also increasing coverage for psychotherapists.',
  },
  lookingToCompensate: {
    defaultMessage:
      'After your first complementary session, will you be looking to be reimbursed by insurance for your paid sessions?',
  },
  whichTypeOfProvider: {
    defaultMessage:
      'Which of the following types of providers does your coverage include?',
  },
  pleaseContactYourInsuranceCarrier: {
    defaultMessage:
      'Please contact your insurance carrier for this information if necessary.',
  },
  yesInsuranceRegisteredPsy: {
    defaultMessage: 'Registered Psychologists',
  },
  yesInsuranceRegisteredSocial: {
    defaultMessage: 'Registered Social Workers (Master of Social Work)',
  },
  yesInsuranceRegisteredPyscho: {
    defaultMessage:
      'Registered Psychotherapists (and in BC, Registered Clinical Counsellors)',
  },
  afterYourFirst: {
    defaultMessage:
      'Will you be using your insurance benefits to pay for sessions after your first complimentary or employer-sponsored session(s)?',
  },
  usingInsurance: {
    defaultMessage: 'Will you be using your insurance to pay for sessions?',
  },
  rsw: { defaultMessage: 'Registered Social Worker' },
  rp: { defaultMessage: 'Registered Psychotherapist' },
  cth: { defaultMessage: 'Counselling Therapist' },
  ccc: {
    defaultMessage: 'Canadian Certified Counsellor',
  },
  rcc: {
    defaultMessage: 'Registered Clinical Counsellor',
  },
  rct: {
    defaultMessage: 'Registered Counselling Therapist',
  },
  lct: {
    defaultMessage: 'Licensed Counselling Therapist',
  },
  rmft: {
    defaultMessage: 'Registered Marriage and Family Therapist',
  },
  rpc: {
    defaultMessage: 'Registered Professional Counsellor',
  },
  langTitle: {
    defaultMessage: 'Preferences',
  },
  langQuestion: {
    defaultMessage: 'Select your preferred language',
  },
  langInstruction: {
    defaultMessage: 'You must select at least one',
  },
  langSeeMore: {
    defaultMessage: 'See more options',
  },
  introduceYourself: {
    defaultMessage: 'Introduce Yourself',
  },
  pleaseDescribe: {
    defaultMessage:
      'Please describe your reason(s) for seeking counselling or life coaching. Your answer will help your provider prepare for your first session.',
  },
  optionalTextBold: {
    defaultMessage: 'This is optional',
  },
  optionalText: {
    defaultMessage: '{bold} and can be as short or long as you wish.',
  },
  introSample: {
    defaultMessage: 'e.g. I am having difficulties in my marriage',
  },
  prefGeneralAvailability: {
    defaultMessage: 'What is your general availability?',
  },
  prefSchedule: {
    defaultMessage:
      'This will help us find a practitioner that works with your schedule',
  },
  phoneVerification: {
    defaultMessage: 'Phone Number Verification',
  },
  phoneVerificationAuth: {
    defaultMessage:
      'Your mobile phone number is needed to authenticate your account and also for security and safety purposes. ',
  },
  phoneCountryCode: {
    defaultMessage: 'Country Code',
  },
  phoneNumber: {
    defaultMessage: 'Phone Number',
  },
  phoneGetPin: {
    defaultMessage: 'Get Pin',
  },
  phoneSevenDigitPin: {
    defaultMessage: 'Enter the 7-digit PIN sent to you by SMS.',
  },
  landlineSevenDigitPin: {
    defaultMessage:
      'You will receive a call at the number you provided. Enter the 7-digit PIN.',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong!',
  },
  phoneDidntGetCode: {
    defaultMessage: "Didn't get pin?",
  },
  phoneResend: {
    defaultMessage: 'Resend',
  },
  phoneDontHaveMobile: {
    defaultMessage: "I don't have a mobile phone",
  },
  verifyPhoneLater: {
    defaultMessage: 'I want to verify my phone number later.',
  },
  incorrectCode: {
    defaultMessage: 'Incorrect code',
  },
  textedVerification: {
    defaultMessage: 'You have been texted a verification code!',
  },
  calledVerification: {
    defaultMessage: 'You will receive a call with a verification code!',
  },
  phoneNumberVerified: {
    defaultMessage: 'Your phone number has been verified!',
  },
  partnerInvitation: {
    defaultMessage: 'Partner Invitation',
  },
  whatEmail: {
    defaultMessage:
      'What is the email address of your partner for the invitation?',
  },
  email: {
    defaultMessage: 'Email',
  },
  sendInvite: {
    defaultMessage: 'Send Invite',
  },
  invitationNote: {
    defaultMessage:
      'Note: You are the primary account holder, and will be responsible for scheduling and billing. Your partner will only receive session notifications and be able to join in couples sessions.',
  },
  confirmConfirmation: {
    defaultMessage: 'Confirmation',
  },
  confirmConsent: {
    defaultMessage: 'Consent & Legal',
  },
  confirmInformedConsent: {
    defaultMessage: 'Informed Consent - Clients',
  },
  confirmPleaseRead: {
    defaultMessage: 'Please read the following terms of agreement',
  },
  confirmPleaseReadBenevity: {
    defaultMessage:
      'Please read the following terms of agreement for Benevity users.',
  },
  confirmPleaseReadInformedConsent: {
    defaultMessage:
      'Please carefully read the following Informed Consent which aims at helping you get informed on the services being delivered to you, as well as on the terms and conditions and confidentiality rules and limitations. If you have any questions concerning the Informed Consent, please feel free to ask your Practitioner for additional information and clarification.',
  },
  confirmSelectAll: {
    defaultMessage: 'SelectAll',
  },
  confirmMatch: {
    defaultMessage: 'Confirm Match',
  },
  confirmMeet: {
    defaultMessage: '“Hello {name}, looking forward to meeting you.“',
  },
  country: {
    defaultMessage: 'Country',
  },
  yourDetails: {
    defaultMessage: 'Your Details',
  },
  yourSafety: {
    defaultMessage:
      'This information is needed for safety reasons and to match you to a local provider.',
  },
  address: {
    defaultMessage: 'Address 1',
  },
  alternateStreet: {
    defaultMessage: 'Address 2',
  },
  cityTown: {
    defaultMessage: 'City/Town',
  },
  stateProvince: {
    defaultMessage: 'Province/Territory/State',
  },
  postalCode: {
    defaultMessage: 'Postal/Zip Code',
  },
  dateOfBirth: {
    defaultMessage: 'Date of Birth',
  },
  gender: {
    defaultMessage: 'Gender Identity',
  },
  male: {
    defaultMessage: 'Male',
  },
  female: {
    defaultMessage: 'Female',
  },
  custom: {
    defaultMessage: 'Custom',
  },
  notSpecified: {
    defaultMessage: 'Prefer not to say',
  },
  timezones: {
    defaultMessage: 'Time Zone',
  },
  streetAddress: {
    defaultMessage: 'Street address',
  },
  aptNumber: {
    defaultMessage: 'Apartment, suite, unit, etc.',
  },
  datePlaceholder: {
    defaultMessage: 'DD/MM/YYYY',
  },
  alberta: { defaultMessage: 'Alberta' },
  britishColumbia: {
    defaultMessage: 'British Columbia',
  },
  manitoba: { defaultMessage: 'Manitoba' },
  newBrunswick: {
    defaultMessage: 'New Brunswick',
  },
  newfoundland: {
    defaultMessage: 'Newfoundland and Labrador',
  },
  northwest: {
    defaultMessage: 'Northwest Territories',
  },
  novaScotia: { defaultMessage: 'Nova Scotia' },
  nunavut: { defaultMessage: 'Nunavut' },
  ontario: { defaultMessage: 'Ontario' },
  pei: {
    defaultMessage: 'Prince Edward Island',
  },
  quebec: { defaultMessage: 'Quebec' },
  saskatchewan: {
    defaultMessage: 'Saskatchewan',
  },
  yukon: { defaultMessage: 'Yukon Territory' },
  ht: {
    defaultMessage: 'Hawaii‑Aleutian Time',
  },
  akt: {
    defaultMessage: 'Alaskan Time',
  },
  pt: {
    defaultMessage: 'Pacific Time (Los Angeles/Vancouver)',
  },
  mt: {
    defaultMessage: 'Mountain Time (Denver/Edmonton)',
  },
  ct: {
    defaultMessage: 'Central Time (Chicago/Winnipeg)',
  },
  et: {
    defaultMessage: 'Eastern Time (New York/Toronto)',
  },
  at: {
    defaultMessage: 'Atlantic Time (Halifax)',
  },
  st: {
    defaultMessage: 'Saskatchewan (Central Standard Time)',
  },
  nt: {
    defaultMessage: 'Newfoundland Time',
  },
  mst: {
    defaultMessage: 'Yukon (Mountain Standard Time)',
  },
  est: {
    defaultMessage: 'Pickle Lake/Atikokan (Eastern Standard Time)',
  },
  pdTitle: {
    defaultMessage: 'Parent / Guardian Details',
  },
  pdSubTitle: {
    defaultMessage: 'This information is needed for safety and consent reasons',
  },
  pdFormLabel: {
    defaultMessage: 'Parent / Guardian',
  },
  pdFirstNameLabel: {
    defaultMessage: 'First Name',
  },
  pdLastNameLabel: {
    defaultMessage: 'Last Name',
  },
  pdRelationshipLabel: {
    defaultMessage: 'Relationship',
  },
  pdEmailLabel: {
    defaultMessage: 'Email Address',
  },
  pdPhoneNumberLabel: {
    defaultMessage: 'Phone Number',
  },
  pdAlternatePhoneNumberLabel: {
    defaultMessage: 'Alternate Phone Number',
  },
  pdAddressCheckBox: {
    defaultMessage: 'Address same as person seeking therapy',
  },
  pdAdditionalParentButton: {
    defaultMessage: 'Add additional parents / guardians',
  },
  pdRemovalParentButton: {
    defaultMessage: 'Remove parent / guardian',
  },
  ymTitle: {
    defaultMessage: 'Your Matches',
  },
  ymDescription: {
    defaultMessage:
      'Below are the practitioners that are the best fit based on your preferences. If they have not set availability, you will be matched, then we will message the provider to request a session.',
  },
  ymResponseTime: {
    defaultMessage: 'Typical practitioner response time is within 48 hours.',
  },
  ymPleaseContact: {
    defaultMessage:
      "If you have specific needs and preferences that are not reflected in any of the practitioners' profiles listed below, please <emailLink>contact</emailLink> our support team and we will match you with a more suitable practitioner from our network.",
  },
  ymMatchingCode: {
    defaultMessage: 'Direct Matching Code',
  },
  ymRankedByFit: {
    defaultMessage: 'Matches ranked by fit',
  },
  ymRankedByAvail: {
    defaultMessage: 'Matches ranked by availability',
  },
  ymSelectPractitioner: {
    defaultMessage: 'Select Practitioner',
  },
  ymLanguage: {
    defaultMessage: 'Languages',
  },
  ymInsurance: {
    defaultMessage: 'Insurance network',
  },
  ymApproach: {
    defaultMessage: 'Approach to Care',
  },
  ymSeeMore: {
    defaultMessage: 'See more',
  },
  ymMoreDetails: {
    defaultMessage: 'More details',
  },
  ymLessDetails: {
    defaultMessage: 'Less details',
  },
  ymBio: {
    defaultMessage: 'Practitioner Bio',
  },
  ymDesignation: {
    defaultMessage: 'Designations',
  },
  ymQualification: {
    defaultMessage: 'Qualifications',
  },
  ymHeHim: {
    defaultMessage: 'He/Him',
  },
  ymSheHer: {
    defaultMessage: 'She/Her',
  },
  ymTheyThem: {
    defaultMessage: 'They/Them',
  },

  ymAsianSouth: {
    defaultMessage: 'Asian - South (e.g. Indian, Pakistani, Sri Lankan)',
  },
  ymAsianEast: {
    defaultMessage: 'Asian - East (e.g. Chinese, Japanese, Korean)',
  },
  ymAsianSouthEast: {
    defaultMessage: 'Asian - South East (e.g. Malaysian, Filipino, Vietnamese)',
  },
  ymAsianNorth: {
    defaultMessage: 'Asian - North American (e.g. Canadian, American)',
  },
  ymAsianCarib: {
    defaultMessage: 'Asian - Caribbean',
  },
  ymBlackAfrican: {
    defaultMessage: 'Black - African (e.g. Ghanaian, Kenyan, Somali)',
  },
  ymBlackCarbi: {
    defaultMessage: 'Black - Caribbean (e.g. Barbadian, Jamaican)',
  },
  ymBlackEuro: {
    defaultMessage: 'Black - European (e.g. English, Dutch, Portuguese)',
  },
  ymBlackLatin: {
    defaultMessage: 'Black - Latin American',
  },
  ymBlackNorth: {
    defaultMessage: 'Black - North American (e.g. Canadian, American)',
  },
  ymFirstNation: {
    defaultMessage: 'First Nations',
  },
  ymIndianCarib: {
    defaultMessage: 'Indian - Caribbean (e.g. Guyanese with origins in India)',
  },
  ymIndige: {
    defaultMessage: 'Indigenous',
  },
  ymInuit: {
    defaultMessage: 'Inuit',
  },
  ymLatinAmeri: {
    defaultMessage: 'Latin American (e.g. Argentinean, Chilean, Salvadoran)',
  },
  ymMetis: {
    defaultMessage: 'Métis',
  },
  ymMiddleEast: {
    defaultMessage:
      'Middle Eastern/North African (e.g. Egyptian, Iranian, Lebanese)',
  },
  ymPacific: {
    defaultMessage: 'Pacific Islander (e.g. Hawaii, Guam, Samoa)',
  },
  ymWhiteEuro: {
    defaultMessage:
      'White - European (e.g. English, Italian, Portuguese, Russian)',
  },
  ymWhiteNorth: {
    defaultMessage: 'White - North American (e.g. Canadian, American)',
  },
  ymWhiteCarib: {
    defaultMessage: 'White - Caribbean',
  },
  ymOther: {
    defaultMessage: 'Other, none of the above',
  },

  ymMan: {
    defaultMessage: 'Man',
  },
  ymWoman: {
    defaultMessage: 'Woman',
  },
  ymGender: {
    defaultMessage: 'Non Binary',
  },
  ymTrans: {
    defaultMessage: 'Transgender',
  },
  ymIdentifyAs: {
    defaultMessage: 'Identifies As',
  },
  ymLocation: {
    defaultMessage: 'Location',
  },
  ymAvailability: {
    defaultMessage: 'General Availability',
  },
  ymNextAvailability: {
    defaultMessage: 'Next Availability',
  },
  ymMessageAvailability: {
    defaultMessage: 'Message practitioner to request availability',
  },
  ymLoadingResults: {
    defaultMessage: 'Loading Results...',
  },
  ymGeneratingMatches: {
    defaultMessage: 'Generating Matches...',
  },
  ymUseDirectCode: {
    defaultMessage: 'Use direct matching code?',
  },
  ymLgbtq: {
    defaultMessage: 'LGBTQ2S+',
  },
  ymNeurodivergent: {
    defaultMessage: 'Neurodivergent Person',
  },
  ymWithDisability: {
    defaultMessage: 'Person Living with a Disability',
  },
  tryChangeFilter: {
    defaultMessage:
      'Try changing or removing some of your session type filters.',
  },
  stillWantToMatchInPerson: {
    defaultMessage: 'I still want to match with a care provider in person',
  },
  tryOurBest: {
    defaultMessage: 'We will try our best to find someone near you.',
  },
  noMatchesFound: {
    defaultMessage: 'No matches found',
  },
  wellHelpYou: {
    defaultMessage:
      "Please contact <emailLink>support@inkblottherapy.com</emailLink> and we'll help you find a provider.",
  },
  somethingWrong: {
    defaultMessage: 'Something went wrong.',
  },
  pleaseRefresh: {
    defaultMessage: 'Please refresh the page or {link}!',
  },
  tryAgain: {
    defaultMessage: 'try again',
  },
  weUseThe: {
    defaultMessage:
      'We use the experience of other users with similar responses to shortlist care providers.',
  },
  pleaseComplete: {
    defaultMessage: 'Please complete',
  },
  mustSelect: {
    defaultMessage: 'Must select at least one language',
  },
  required: {
    defaultMessage: 'Required',
  },
  tncCheckBoxErrorMessages: {
    defaultMessage: 'Please indicate your agreement by checking the box. ',
  },
  validDate: {
    defaultMessage: 'Not a valid date',
  },
  yearRange: {
    defaultMessage: 'Year is out of range. Please use DD/MM/YYYY',
  },
  monthRange: {
    defaultMessage: 'Month is out of range. Please use DD/MM/YYYY',
  },
  dayRange: {
    defaultMessage: 'Day is out of range. Please use DD/MM/YYYY',
  },
  minimumAge: {
    defaultMessage: 'Must be at least 15 years of age',
  },
  passwordLength: {
    defaultMessage: 'Password must be at least 8 characters',
  },
  passwordMatch: {
    defaultMessage: "Passwords don't match",
  },
  emailMatch: {
    defaultMessage: "Emails don't match",
  },
  invalidPhone: {
    defaultMessage: 'Please enter a phone number - Example, 123-456-7890',
  },
  invalidLoanAmount: {
    defaultMessage: 'Please enter a number - Example, $100.00',
  },
  validEmail: {
    defaultMessage: 'Please enter an email address - Example, user@example.com',
  },
  invalidPostalCode: {
    defaultMessage: 'Invalid postal code',
  },
  invalidZIPCode: {
    defaultMessage: 'Invalid ZIP code',
  },
  monFri: {
    defaultMessage: 'MON-FRI',
  },
  satSun: {
    defaultMessage: 'SAT-SUN',
  },
  excellent: {
    defaultMessage: 'Excellent match',
  },
  veryGood: {
    defaultMessage: 'Very good match',
  },
  average: {
    defaultMessage: 'Average match',
  },
  ok: {
    defaultMessage: 'OK',
  },
  returnToDashboard: {
    defaultMessage: 'Return to Dashboard',
  },
  sendRequest: {
    defaultMessage: 'Send Request',
  },
  coughlinConsent: {
    defaultMessage:
      'I give consent to have my costs for paid sessions covered through my Benefits Administrator. For this, I consent to the release of my personal non-medical information to facilitate this payment.',
  },
  coughlinNumber: {
    defaultMessage: 'Number',
  },
  emergencyNumber: {
    defaultMessage: 'Emergency Contact Number',
  },
  emergencyName: {
    defaultMessage: 'Emergency Contact Name',
  },
  emergencyRelationship: {
    defaultMessage: 'Emergency Contact Relationship',
  },
  viewOnMap: {
    defaultMessage: 'View on Map',
  },
  inPerson: {
    defaultMessage: 'In-person',
  },
  video: {
    defaultMessage: 'Video',
  },
  voice: {
    defaultMessage: 'Voice',
  },
  priceAfterSixty: {
    defaultMessage: 'Price after sponsored sessions (60 minutes)',
  },
  taxIfApplicable: {
    defaultMessage: '(+tax if applicable)',
  },
  locationNotAvailable: {
    defaultMessage: 'Location information not available.',
  },
  rankMatches: {
    defaultMessage: 'Rank Matches:',
  },
  byFit: {
    defaultMessage: 'By Fit',
  },
  byNextAvailability: {
    defaultMessage: 'By Next Availability',
  },
  byDistance: {
    defaultMessage: 'By Distance',
  },
  distance: {
    defaultMessage: 'Distance',
  },
  any: {
    defaultMessage: 'Any',
  },
  sessionModality: {
    defaultMessage: 'Session Modality:',
  },
  atbWork: {
    defaultMessage:
      'In the past six weeks, how much work have you missed due to mental health reasons (best estimate)?',
  },
  atbProductivity: {
    defaultMessage:
      'In the past six weeks, while working, how much productive time have you lost due to mental health reasons (best estimate)?',
  },
  atbNone: {
    defaultMessage: 'none',
  },
  atbDay: {
    defaultMessage: '{amount} day',
  },
  atbDays: {
    defaultMessage: '{amount} days',
  },
  atbHours: {
    defaultMessage: '{amount} hours',
  },
  loading: {
    defaultMessage: 'Loading...',
  },
  zone: {
    defaultMessage: 'Zone',
  },
  covidInPerson: {
    defaultMessage:
      'Due to precautions related to COVID-19, there are limited options for in-person care. ',
  },
  aboutToMatch: {
    defaultMessage:
      '{notInProv} If you plan to use insurance to pay for your sessions, please check your insurance coverage before booking.',
  },
  goBack: {
    defaultMessage: 'Go back',
  },
  matchWith: {
    defaultMessage: 'Match with practitioner',
  },
  specQuestion: {
    defaultMessage:
      'Do you prefer a counsellor with any of the following specialities?',
  },
  informationForMinors: {
    defaultMessage: 'Information for Minors',
  },
  appearsYouAreAMinor: {
    defaultMessage:
      'It appears that you are a minor in the province of {province}. Here are some things to know before your first session:',
  },
  parentsOrGuardian: {
    defaultMessage:
      '1) <b>One of your parents or your legal guardian will need to come to your first session.</b> If this is not something that will be possible, please contact support via live chat at the bottom right corner, or <emailLink>via email</emailLink>.',
  },
  mayBeShared: {
    defaultMessage:
      "2) <b>Some of the contents of your therapy sessions may be shared with your parent(s) / guardian(s).</b> This depends on the province that you live and what agreement you come to with your counsellor during your first meeting. It's important that everyone involved is on the same page in terms of what will and will not be shared.",
  },
  minorDangerousSituation: {
    defaultMessage:
      '3) A counsellor must always tell a parent / guardian or other authority if a minor is in a dangerous situation.',
  },
  moreInformationForMinors: {
    defaultMessage: 'More information for Minors',
  },
  aboveInformation: {
    defaultMessage:
      'I confirm that I have read and understood the above information',
  },
  seeInPerson: {
    defaultMessage: 'See a counsellor in-person',
  },
  matchInPerson: {
    defaultMessage:
      'Request to match with a care provider for an in-person session',
  },
  happyToAssist: {
    defaultMessage:
      'When you submit your request, a member of our client care team will help look for a suitable provider that is offering in-person sessions near you. Our team will get in touch with you with an update as it becomes available.',
  },
  preferMatchNow: {
    defaultMessage:
      '<b>Prefer to match with a counsellor now?</b> You can browse Inkblot counsellors offering virtual therapy and choose one that best meets your needs.',
  },
  virtualTherapyCompare: {
    defaultMessage: 'How does virtual therapy compare to in-person therapy?',
  },
  additionalDetails: {
    defaultMessage:
      'Please include additional details or comments for your in-person care provider request below.',
  },
  includeInformation: {
    defaultMessage:
      'You may want to include information about how far you are willing to travel, mode of transportation, accessibility requirements, etc.',
  },
  continueVirtual: {
    defaultMessage: 'Continue with virtual therapy',
  },
  requestInPerson: {
    defaultMessage: 'Request an in-person counsellor',
  },
  requestSent: {
    defaultMessage:
      'Your request has been received and our team is working on it',
  },
  allowHours: {
    defaultMessage:
      'A member of our team will get in touch with you with an update as it becomes available.',
  },
  goToDashboard: {
    defaultMessage: 'Go to dashboard',
  },
  supportEmail: {
    defaultMessage: 'support@inkblottherapy.com',
    description: 'User support email address',
  },
  pronouns: {
    defaultMessage: 'Pronouns',
    description: 'User support email address',
  },
  whyCantIChange: {
    defaultMessage: 'Why Can’t I change Some of my Details?',
  },
  changesToCertainFields: {
    defaultMessage:
      'Changes to certain fields must be made through your plan administrator',
  },
  doNotKnow: {
    defaultMessage: 'Do not know',
  },
  genderNonConforming: {
    defaultMessage: 'Gender Fluid/Nonconforming',
  },
  genderQuestioning: {
    defaultMessage: 'Gender Questioning',
  },
  genderQueer: {
    defaultMessage: 'Genderqueer',
  },
  intersex: {
    defaultMessage: 'Intersex',
  },
  transMan: {
    defaultMessage: 'Trans Man',
  },
  transWoman: {
    defaultMessage: 'Trans Woman',
  },
  twoSpirit: {
    defaultMessage: 'Two Spirit',
  },
});
