let rootUrl;
let apiUrl;
let apiUrlNoVersion;
let serverUrl;
let practiceUrl;
let registrationUrl;
let marketingUrl;
let wellnessHubUrl;
let gsplusSignupEnUrl;
let gsplusSignupFrUrl;
let stripeKey;
let googleMapsKey;
let ENV;
let sentryDsn;
let country;
let currency;
let amplitudeApiKey;
let assessUrl;
let ldClientId;
let otherTherapyUrl;
let otherServerUrl;

let defaultDheAzurePolicyId;
let dheAzureTenantName;
let dheAzureClientId;
let pmosScope;
let consentScope;
let pmosApiUrl;
let consentApiUrl;
let userHelperApiUrl;
let cohereProjectKey;
let dheSessionPulseMs = 10000;

const hostname = window && window.location && window.location.hostname;

if (hostname === 'localhost') {
  ENV = 'DEVELOPMENT';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'http://localhost:10101';
  otherTherapyUrl = 'http://localhost:10101';
  apiUrl = 'http://localhost:3000/api/v2';
  apiUrlNoVersion = 'http://localhost:3000/api';
  serverUrl = 'http://localhost:3000';
  otherServerUrl = 'http://localhost:3000';
  practiceUrl = 'http://localhost:8080';
  registrationUrl = 'http://localhost:5173';
  marketingUrl = 'http://localhost:8000';
  wellnessHubUrl = 'http://localhost:8000';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  googleMapsKey = 'AIzaSyAc6g5T57bLRAYNCEnRfJE911KuE2FjbH0';
  sentryDsn = null;
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  assessUrl = 'http://localhost:7070';
  ldClientId = '61aa7abf805c8710cfad8b2e';
  defaultDheAzurePolicyId = 'b2c_1a_dhe_signin_mfa';
  dheAzureTenantName = 'gscdevb2c';
  dheAzureClientId = 'c894ad0e-877c-419c-8ccc-e2854a21cb5d';
  pmosScope = 'https://gscdevb2c.onmicrosoft.com/digital-health-api/pmos-api';
  pmosApiUrl = 'https://api-dev.onlineweb.ca/pmos-api-b2c';
  consentScope =
    'https://gscdevb2c.onmicrosoft.com/dhe-consent-service/outcomes:read_write';
  consentApiUrl = 'https://api-dev.greenshieldplus.ca/consent/v1/';
  userHelperApiUrl = 'https://api-dev.greenshieldplus.ca/userhelper/v1/';
  cohereProjectKey = 'oDBDvrYQuOh_IpHF18tgaaQf';
} else if (
  hostname === 'test.inkblottherapy.com' ||
  hostname === 'staging.inkblottherapy.com'
) {
  ENV = 'STAGING';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://staging.inkblottherapy.com';
  otherTherapyUrl = 'https://us-staging.inkblottherapy.com';
  apiUrl = 'https://api.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://api.inkblottherapy.com/api';
  serverUrl = 'https://api.inkblottherapy.com';
  otherServerUrl = 'https://api.inkblottherapy.com';
  practiceUrl = 'https://practitioner-stg.greenshieldplus.ca';
  registrationUrl = 'https://registration-staging.inkblottherapy.com';
  marketingUrl = 'https://staging-marketing.inkblottherapy.com';
  wellnessHubUrl = 'https://wellnesshub.inkblottherapy.com';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  googleMapsKey = 'AIzaSyAc6g5T57bLRAYNCEnRfJE911KuE2FjbH0';
  sentryDsn = null;
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '61ba3223f940930e6232e840';
  defaultDheAzurePolicyId = 'b2c_1a_dhe_signin_mfa';
  dheAzureTenantName = 'gsccatb2c';
  dheAzureClientId = '0003d7e1-ed4b-49af-9a49-a5710fe6a8f8';
  pmosScope = 'https://gsccatb2c.onmicrosoft.com/digital-health-api/pmos-api';
  pmosApiUrl = 'https://api-cat.onlineweb.ca/pmos-api-b2c';
  consentScope =
    'https://gsccatb2c.onmicrosoft.com/dhe-consent-service/outcomes:read_write';
  consentApiUrl = 'https://api-cat.greenshieldplus.ca/consent/v1/';
  userHelperApiUrl = 'https://api-cat.greenshieldplus.ca/userhelper/v1/';
  cohereProjectKey = 'oDBDvrYQuOh_IpHF18tgaaQf';
} else if (hostname === 'app.inkblottherapy.com') {
  ENV = 'PRODUCTION';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://app.inkblottherapy.com';
  otherTherapyUrl = 'https://portal.inkblottherapy.com';
  apiUrl = 'https://api.inkblotpractice.com/api/v2';
  apiUrlNoVersion = 'https://api.inkblotpractice.com/api';
  serverUrl = 'https://api.inkblotpractice.com';
  otherServerUrl = 'https://medapi.inkblottherapy.com';
  practiceUrl = 'https://practitioner.greenshieldplus.ca';
  registrationUrl = 'https://registration.inkblottherapy.com';
  marketingUrl = 'https://inkblottherapy.com';
  wellnessHubUrl = 'https://wellnesshub.inkblottherapy.com';
  gsplusSignupEnUrl = 'https://app.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://app.greenshieldplus.ca/fr/sign-up';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  googleMapsKey = 'AIzaSyAc6g5T57bLRAYNCEnRfJE911KuE2FjbH0';
  sentryDsn = 'https://4b46f2acb43147169e3b887718b2d002@sentry.io/1461274';
  amplitudeApiKey = '19a913a504c377ea2dc305213318fbb6';
  assessUrl = 'https://inkblotassess.com';
  ldClientId = '61a4f5fda8e6530f447dbcf9';
  defaultDheAzurePolicyId = 'b2c_1a_dhe_signin_mfa';
  dheAzureTenantName = 'gscprodb2c';
  dheAzureClientId = 'eb669a0f-97dc-4743-a6d1-c9ba06ee1df5';
  pmosScope = 'https://gscprodb2c.onmicrosoft.com/digital-health-api/pmos-api';
  pmosApiUrl = 'https://api.onlineweb.ca/pmos-api-b2c';
  consentScope =
    'https://gscprodb2c.onmicrosoft.com/dhe-consent-service/outcomes:read_write';
  consentApiUrl = 'https://api.greenshieldplus.ca/consent/v1/';
  userHelperApiUrl = 'https://api.greenshieldplus.ca/userhelper/v1/';
  cohereProjectKey = 'MU2MvgXLwkLjGCA8wyNCfvEk';
} else if (hostname === 'uat.inkblottherapy.com') {
  ENV = 'UAT';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://uat.inkblottherapy.com';
  otherTherapyUrl = 'https://uat.inkblottherapy.com';
  apiUrl = 'https://uatapi.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://uatapi.inkblottherapy.com/api';
  serverUrl = 'https://uatapi.inkblottherapy.com';
  otherServerUrl = 'https://uatapi.inkblottherapy.com';
  practiceUrl = 'https://uat.inkblotpractice.com';
  marketingUrl = 'https://uat-marketing.inkblottherapy.com';
  wellnessHubUrl = 'https://wellness-hub.netlify.app';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  stripeKey = '';
  googleMapsKey = 'AIzaSyAc6g5T57bLRAYNCEnRfJE911KuE2FjbH0';
  sentryDsn = null;
  amplitudeApiKey = null;
  ldClientId = '61ba324c1c39840e58ab0ffd';
  cohereProjectKey = 'oDBDvrYQuOh_IpHF18tgaaQf';
} else if (hostname === 'demo.inkblottherapy.com') {
  ENV = 'DEMO';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://demo.inkblottherapy.com';
  otherTherapyUrl = 'https://demo.inkblottherapy.com';
  apiUrl = 'https://demoapi.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://demoapi.inkblottherapy.com/api';
  serverUrl = 'https://demoapi.inkblottherapy.com';
  otherServerUrl = 'https://demoapi.inkblottherapy.com';
  practiceUrl = 'https://demo.inkblotpractice.com';
  marketingUrl = 'https://demo-marketing.inkblottherapy.com';
  wellnessHubUrl = 'https://wellness-hub.netlify.app';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  googleMapsKey = 'AIzaSyAc6g5T57bLRAYNCEnRfJE911KuE2FjbH0';
  sentryDsn = null;
  amplitudeApiKey = null;
  ldClientId = '622b6838855e3b14fa2358b2';
  cohereProjectKey = 'oDBDvrYQuOh_IpHF18tgaaQf';
} else if (hostname === 'us-staging.inkblottherapy.com') {
  ENV = 'US DEV';
  country = 'US';
  currency = 'USD';
  rootUrl = 'https://us-staging.inkblottherapy.com';
  otherTherapyUrl = 'https://staging.inkblottherapy.com';
  apiUrl = 'https://usapi.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://usapi.inkblottherapy.com/api';
  serverUrl = 'https://usapi.inkblottherapy.com';
  otherServerUrl = 'https://usapi.inkblottherapy.com';
  practiceUrl = 'https://us-staging.inkblotpractice.com';
  marketingUrl = 'https://us-staging-marketing.inkblottherapy.com';
  wellnessHubUrl = 'https://wellness-hub.netlify.app';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  registrationUrl = 'https://registration-staging.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  googleMapsKey = 'AIzaSyAc6g5T57bLRAYNCEnRfJE911KuE2FjbH0';
  sentryDsn = null;
  amplitudeApiKey = null;
  ldClientId = '61ba3223f940930e6232e840';
  cohereProjectKey = 'oDBDvrYQuOh_IpHF18tgaaQf';
} else if (hostname === 'portal.inkblottherapy.com') {
  ENV = 'US PROD';
  country = 'US';
  currency = 'USD';
  rootUrl = 'https://portal.inkblottherapy.com';
  otherTherapyUrl = 'https://app.inkblottherapy.com';
  apiUrl = 'https://medapi.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://medapi.inkblottherapy.com/api';
  serverUrl = 'https://medapi.inkblottherapy.com';
  otherServerUrl = 'https://api.inkblotpractice.com';
  practiceUrl = 'https://portal.inkblotpractice.com';
  registrationUrl = 'https://registration.inkblottherapy.com';
  marketingUrl = 'https://us.inkblottherapy.com';
  wellnessHubUrl = 'https://wellness-hub.netlify.app';
  gsplusSignupEnUrl = 'https://dev.greenshieldplus.ca/en/sign-up';
  gsplusSignupFrUrl = 'https://dev.greenshieldplus.ca/fr/sign-up';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  googleMapsKey = 'AIzaSyAc6g5T57bLRAYNCEnRfJE911KuE2FjbH0';
  assessUrl = 'https://us.inkblotassess.com';
  sentryDsn = 'https://4b46f2acb43147169e3b887718b2d002@sentry.io/1461274';
  amplitudeApiKey = '19a913a504c377ea2dc305213318fbb6';
  ldClientId = '61a4f5fda8e6530f447dbcf9';
  cohereProjectKey = 'oDBDvrYQuOh_IpHF18tgaaQf';
} else if (hostname === 'qa1-app.inkblottherapy.com') {
  ENV = 'QA1';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://qa1-app.inkblottherapy.com';
  otherTherapyUrl = 'https://qa1-app.inkblottherapy.com';
  apiUrl = 'https://qa1-api.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://qa1-api.inkblottherapy.com/api';
  serverUrl = 'https://qa1-api.inkblottherapy.com';
  otherServerUrl = 'https://qa1-api.inkblottherapy.com';
  practiceUrl = 'https://qa1-app.inkblotpractice.com';
  marketingUrl = 'https://qa1.inkblottherapy.com';
  wellnessHubUrl = 'https://qa1-wellnesshub.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  googleMapsKey = 'AIzaSyAc6g5T57bLRAYNCEnRfJE911KuE2FjbH0';
  sentryDsn = null;
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '61ba3223f940930e6232e840';
  defaultDheAzurePolicyId = 'b2c_1a_dhe_signin_mfa';
  dheAzureTenantName = 'gscqab2c2';
  dheAzureClientId = 'd2a07da4-fd57-414b-8340-7979ca2c9ee0';
  pmosScope = 'https://gscqab2c2.onmicrosoft.com/digital-health-api/pmos-api';
  consentScope =
    'https://gscqab2c2.onmicrosoft.com/dhe-consent-service/outcomes:read_write';
  pmosApiUrl = 'https://api-qa.onlineweb.ca/pmos-api-b2c';
  consentApiUrl = 'https://api-qa.greenshieldplus.ca/consent/v1/';
  userHelperApiUrl = 'https://api-qa.greenshieldplus.ca/userhelper/v1/';
  cohereProjectKey = 'oDBDvrYQuOh_IpHF18tgaaQf';
} else if (hostname === 'qa2-app.inkblottherapy.com') {
  ENV = 'QA2';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://qa2-app.inkblottherapy.com';
  otherTherapyUrl = 'https://qa2-app.inkblottherapy.com';
  apiUrl = 'https://qa2-api.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://qa2-api.inkblottherapy.com/api';
  serverUrl = 'https://qa2-api.inkblottherapy.com';
  otherServerUrl = 'https://qa2-api.inkblottherapy.com';
  practiceUrl = 'https://qa2-app.inkblotpractice.com';
  marketingUrl = 'https://qa2.inkblottherapy.com';
  wellnessHubUrl = 'https://qa2-wellnesshub.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  googleMapsKey = 'AIzaSyAc6g5T57bLRAYNCEnRfJE911KuE2FjbH0';
  sentryDsn = null;
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '61ba3223f940930e6232e840';
  defaultDheAzurePolicyId = 'b2c_1a_dhe_signin_mfa';
  dheAzureTenantName = 'gscqab2c2';
  registrationUrl = 'https://registration-qa2.inkblottherapy.com';
  dheAzureClientId = 'd2a07da4-fd57-414b-8340-7979ca2c9ee0';
  pmosScope = 'https://gscqab2c2.onmicrosoft.com/digital-health-api/pmos-api';
  consentScope =
    'https://gscqab2c2.onmicrosoft.com/dhe-consent-service/outcomes:read_write';
  pmosApiUrl = 'https://api-qa.onlineweb.ca/pmos-api-b2c';
  consentApiUrl = 'https://api-qa.greenshieldplus.ca/consent/v1/';
  userHelperApiUrl = 'https://api-qa.greenshieldplus.ca/userhelper/v1/';
  cohereProjectKey = 'oDBDvrYQuOh_IpHF18tgaaQf';
}

export const ROOT_URL = `${rootUrl}`;
export const API_URL = `${apiUrl}`;
export const API_URL_NO_VERSION = `${apiUrlNoVersion}`;
export const SERVER_URL = `${serverUrl}`;
export const OTHER_SERVER_URL = `${otherServerUrl}`;
export const PRACTICE_URL = `${practiceUrl}`;
export const REGISTRATION_URL = `${registrationUrl}`;
export const MARKETING_URL = `${marketingUrl}`;
export const WELLNESS_HUB_URL = `${wellnessHubUrl}`;
export const GSPLUS_SIGNUP_EN_URL = gsplusSignupEnUrl;
export const GSPLUS_SIGNUP_FR_URL = gsplusSignupFrUrl;

export const STRIPE_KEY = `${stripeKey}`;
export const GOOGLE_MAPS_API_KEY = `${googleMapsKey}`;
export const ENVIRONMENT = `${ENV}`;
export const SENTRY_DSN = sentryDsn;
export const COUNTRY = `${country}`;
export const CURRENCY = `${currency}`;
export const AMPLTIUDE_API_KEY = amplitudeApiKey;
export const ASSESS_URL = `${assessUrl}`;
export const LD_CLIENT_ID = `${ldClientId}`;
export const OTHER_THERAPY_URL = `${otherTherapyUrl}`;
export const DHE_AZURE_CLIENT_ID = `${dheAzureClientId}`;
export const DEFAULT_DHE_AZURE_POLICY_ID = `${defaultDheAzurePolicyId}`;
export const DHE_AZURE_TENANT_NAME = `${dheAzureTenantName}`;
export const PMOS_SCOPE = `${pmosScope}`;
export const CONSENT_SCOPE = `${consentScope}`;
export const CONSENT_API_URL = `${consentApiUrl}`;
export const PMOS_API_URL = `${pmosApiUrl}`;
export const USER_HELPER_API_URL = `${userHelperApiUrl}`;
export const DHE_SESSION_PULSE = `${dheSessionPulseMs}`;
export const COHERE_PROJECT_KEY = `${cohereProjectKey}`;
