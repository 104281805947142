/**
 *
 * SelectInput
 *
 */

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import PropTypes from 'prop-types';

import messages from 'containers/Matching/messages';
import P from 'components/P';

import { errorRed } from '../../global-styles';

const Label = styled.p`
  color: #0f2045;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: normal;
  margin-bottom: 10px;
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'rgba(240, 242, 245, 0.79)',
    border: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    height: '40px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#2e5fca',
  }),
  indicatorSeparator: () => ({}),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#ecf9f9' : 'white',
    color: '#0f2045',
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: '16px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: state.isFocused ? '600' : 'normal',
    letterSpacing: '0.1px',
    lineHeight: 'normal',
    overflow: 'hidden',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#0f2045',
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: '16px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: 'rgba(240, 242, 245, 0.79)',
    border: 'none',
    color: '#0f2045',
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: '16px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.1px',
    lineHeight: 'normal',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
  }),
};

const StyledP = styled(P)`
  margin: 10px 0 0 0;
`;

function SelectInput({ ariaLabel, input, options, multi, label, meta, intl }) {
  const { name, value, onBlur, onChange, onFocus } = input;
  const transformedValue = transformValue(value, options, multi);
  const { touched, error } = meta;
  return (
    <div>
      {label && <Label>{<FormattedMessage {...label} />}</Label>}
      <Select
        valueKey="value"
        aria-label={ariaLabel}
        isSearchable={false}
        blurInputOnSelect={false}
        name={name}
        value={transformedValue}
        multi={multi}
        options={options}
        onChange={
          multi ? multiChangeHandler(onChange) : singleChangeHandler(onChange)
        }
        onBlur={() => onBlur(value)}
        onFocus={onFocus}
        styles={customStyles}
        placeholder={intl.formatMessage(messages.select)}
        aria-expanded
      />
      {touched && error && (
        <StyledP color={errorRed}>{touched ? error : ''}</StyledP>
      )}
    </div>
  );
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
  return function handleSingleChange(value) {
    func(value ? value.value : '');
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
  return function handleMultiHandler(values) {
    func(values.map((value) => value.value));
  };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi) {
  if (multi && typeof value === 'string') return [];

  const filteredOptions = options.filter((option) =>
    multi ? value.indexOf(option.value) !== -1 : option.value === value,
  );

  return multi ? filteredOptions : filteredOptions[0];
}

SelectInput.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  multi: PropTypes.bool,
  intl: intlShape,
};

SelectInput.defaultProps = {
  multi: false,
  options: [],
};

export default injectIntl(SelectInput);
