/*
 *
 * UserInformation reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_USER,
  TOGGLE_FREE_SESSION,
  FETCH_USER_ERROR,
  FETCH_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_EMAIL,
  UPDATE_EMAIL_ERROR,
  UPDATE_EMAIL_SUCCESS,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_ERROR,
} from './constants';

export const initialState = fromJS({
  first_name: '',
  last_name: '',
  dob: '',
  email: '',
  avatar: {
    url: undefined,
  },
  address: {
    street: '',
    state: {
      code: '',
    },
  },
  timezone: '',
  primary_phone_number: {
    number: '',
  },
  preferred_name: '',
  loaded: false,
  loading: false,
  error: false,
  emailError: {
    incorrectError: null,
    existsError: null,
  },
  confirmed_at: '',
  locale: '',
});

function userInformationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return state.set('loading', true);
    case FETCH_USER_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_USER_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .mergeDeep(action.user);
    case TOGGLE_FREE_SESSION:
      return state.set('has_a_free_session', action.bool);
    case UPDATE_USER:
      return state.set('loading', true);
    case UPDATE_USER_ERROR:
      return state.set('loading', false).set('error', action.error);
    case UPDATE_USER_SUCCESS:
      return state.set('loading', false).mergeDeep(action.user);
    case UPDATE_EMAIL:
      return state.set('loading', true);
    case UPDATE_EMAIL_ERROR:
      return state
        .set('loading', false)
        .mergeDeep({ emailError: action.error.data });
    case UPDATE_EMAIL_SUCCESS:
      return state
        .set('loading', false)
        .set('email', action.email.value)
        .setIn(['emailError', 'incorrectError'], null)
        .setIn(['emailError', 'existsError'], null);
    case CONFIRM_EMAIL:
      return state.set('loading', true);
    case CONFIRM_EMAIL_ERROR:
      return state.set('loading', false).set('error', action.error);
    case CONFIRM_EMAIL_SUCCESS:
      return state.set('loading', false);
    default:
      return state;
  }
}

export default userInformationReducer;
