import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';

import H2 from 'components/H2';
import H3 from 'components/H3';
import Content from 'components/Content';
import Button from 'components/Button';
import messages from '../messages';
import { tabletSize, phoneSize } from '../../../global-styles';

const StyledContainer = styled.div`
  .pc-availability {
    min-height: 40px;
    padding: 10px 20px;
    margin-top: 40px;
    background-color: rgba(46, 95, 202, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .pc-header {
    display: flex;
    .pc-avatar {
      width: 220px;
      height: 100%;
      background-color: #ecf2f4;
      border-top-left-radius: 20px;
      img {
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        border-top-left-radius: 20px;
      }
    }
    .pc-general {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .pc-title-button {
        display: flex;
        justify-content: space-between;
        .pc-title {
          margin: 20px 0 20px 20px;
        }
        .pc-upper-button {
          margin: 20px 40px 0 0;
        }
      }
      .pc-name {
        margin: 0 0 0 20px;
      }
      .pc-pronouns {
        margin: 10px 0 0 20px;
      }
    }
  }

  .pc-availability-upper {
    display: flex;
  }
  .pc-availability-lower {
    display: none;
  }
  .pc-header-mobile {
    display: none;
  }
  .pc-lower-button {
    display: none;
  }

  @media (max-width: ${tabletSize}) {
    .pc-availability-upper {
      display: none;
    }
    .pc-availability-lower {
      display: flex;
    }
    .pc-header {
      .pc-avatar {
        width: 160px;
        height: 160px;
      }
    }
  }

  @media (max-width: ${phoneSize}) {
    .pc-header {
      display: none;
    }
    .pc-header-mobile {
      display: block;
      .pc-title {
        margin: 20px;
      }
      .pc-avatar-name {
        display: flex;
        margin: 0 20px;
        .pc-avatar {
          width: 80px;
          height: 80px;
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        }
        .pc-name {
          margin-left: 20px;
          display: flex;
          align-items: center;
        }
      }
    }
    .pc-lower-button {
      display: flex;
      justify-content: center;
      margin: 20px 0 40px 0;
    }
  }
`;

const translations = [
  {
    code: 'He/Him',
    label: <FormattedMessage {...messages.ymHeHim} />,
  },
  {
    code: 'She/Her',
    label: <FormattedMessage {...messages.ymSheHer} />,
  },
  {
    code: 'They/Them',
    label: <FormattedMessage {...messages.ymTheyThem} />,
  },
  {
    code: 'Man',
    label: <FormattedMessage {...messages.ymMan} />,
  },
  {
    code: 'Woman',
    label: <FormattedMessage {...messages.ymWoman} />,
  },
  {
    code: 'Gender Non-Binary or Expansive',
    label: <FormattedMessage {...messages.ymGender} />,
  },
  {
    code: 'Transgender',
    label: <FormattedMessage {...messages.ymTrans} />,
  },
];
// /* eslint-enable prettier/prettier */

const getTranslations = (code) => {
  for (let i = 0; i < translations.length; i += 1) {
    if (translations[i].code === code) {
      return translations[i].label;
    }
  }

  return <span>{code}</span>;
};

function Header({ title, provider, bookingUrl, history, isGuest, locale }) {
  const avatar = () =>
    provider.avatar &&
    provider.avatar.url && (
      <img src={provider.avatar.url} alt="Headshot of Provider" />
    );
  const bookingButton = () => {
    if (isGuest) {
      return <noscript />;
    }
    let actionURL = bookingUrl;
    let messageKey = 'bookASession';
    if (provider && !provider.next_availability) {
      actionURL = '/messaging/';
      messageKey = 'messageMyProvider';
    }

    return (
      <Button
        secondary
        label={messages[messageKey]}
        style={{
          margin: '0',
          width: '220px',
          padding: '0 25px',
        }}
        onClick={() => history.push(actionURL)}
      />
    );
  };

  const renderPronouns = () => {
    if (!provider.pronouns || provider.pronouns.size < 1) {
      return <></>;
    }
    const pronounsArray = provider.pronouns.map((pronoun) => pronoun.name);

    const result = pronounsArray.map((noun) => getTranslations(noun));

    const resultArray = [];

    for (let i = 0; i < result.length - 1; i += 1) {
      resultArray.push(<span key={i}>{result[i]}, </span>);
    }
    resultArray.push(<span key="last">{result[result.length - 1]}</span>);

    return <div>{resultArray}</div>;
  };

  const currentlyOutOfOffice = (outOfOffice) =>
    moment(outOfOffice.end_date_string).isAfter(moment()) &&
    moment(outOfOffice.start_date_string).isBefore(moment());

  const nextAvailability = () => {
    if (
      provider.out_of_office &&
      currentlyOutOfOffice(provider.out_of_office) &&
      !moment(provider.next_availability).isValid()
    ) {
      return (
        <Content bold color="#946c00">
          {moment(provider.out_of_office.end_date_string).isValid() && (
            <div>
              <FormattedMessage {...messages.outOfOffice} />:{' '}
              {locale === 'fr'
                ? moment(provider.out_of_office.end_date_string).format(
                    'dddd Do MMMM',
                  )
                : moment(provider.out_of_office.end_date_string).format(
                    'dddd MMMM Do',
                  )}
            </div>
          )}
        </Content>
      );
    }
    return (
      <Content bold color="#2e5fca">
        {moment(provider.next_availability).isValid() ? (
          <div>
            <FormattedMessage {...messages.ymNextAvailability} />:{' '}
            {moment(provider.next_availability).format('dddd MMMM Do, hh:mmA')}
          </div>
        ) : (
          <FormattedMessage {...messages.availabilityUponRequest} />
        )}
      </Content>
    );
  };

  return (
    <StyledContainer>
      {/* Web, Table Header */}
      <div className="pc-header">
        <div className="pc-avatar">{avatar()}</div>
        <div className="pc-general">
          <div className="pc-title-button">
            <div className="pc-title">
              <H2>{title}</H2>
              <H3 style={{ lineHeight: '10px' }}>
                {provider.clinical_program_category}
              </H3>
            </div>
            <div className="pc-upper-button">{bookingButton()}</div>
          </div>
          <div className="pc-name">
            <H3>{provider.full_name}</H3>
          </div>
          <div className="pc-pronouns">
            <div>{renderPronouns()}</div>
          </div>
          <div className="pc-availability pc-availability-upper">
            {nextAvailability()}
          </div>
        </div>
      </div>
      {/* Mobile Header */}
      <div className="pc-header-mobile">
        <div className="pc-title">
          <H2>{title}</H2>
        </div>
        <div className="pc-avatar-name">
          <div className="pc-avatar">{avatar()}</div>
          <div className="pc-name">
            <H3>{provider.full_name}</H3>
          </div>
        </div>
      </div>
      <div className="pc-availability pc-availability-lower">
        {nextAvailability()}
      </div>
      <div className="pc-lower-button">{bookingButton()}</div>
    </StyledContainer>
  );
}

Header.propTypes = {
  provider: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  bookingUrl: PropTypes.string.isRequired,
  isGuest: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

export default withRouter(Header);
