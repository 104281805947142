import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import SolidIcons from '../../images/Heroicons/solid/index';
const StyledIcon = styled(SVG)`
  svg {
    width: 16px;
    height: 16px;
  }
  & path {
    fill: ${({ color }) => color || '#808597'};
    color: white;
  }
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SqIcon = (props) => {
  const { isSolid = true, name } = props;
  const IconSrc = isSolid && SolidIcons[name];
  return <StyledIcon src={IconSrc} color={props.color} {...props} />;
};

export default SqIcon;
