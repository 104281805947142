/**
 *
 * Dialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import 'wicg-inert';
import ReactDOM from 'react-dom';

import { phoneSize } from 'global-styles';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import Close from '../../images/Dialog/close.svg';
import reducer from './reducer';

const Container = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
`;

const Overlay = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 32, 69, 0.6);
`;

const BodyContainer = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  margin-left: 25px;
  margin-right: 25px;
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;
  z-index: 9001;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
`;

const Top = styled.div`
  position: relative;
  background-color: #ffffff;
  height: 60px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  .close {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 15px;
  }
`;

const Body = styled.div`
  background-color: #ffffff;
  max-height: 90vh;
  overflow-y: auto;
  padding-bottom: 40px;
  &.bodyOnly {
    border-radius: 20px;
    padding: 0;
    margin: 0;
    @media (max-width: ${phoneSize}) {
      max-height: 80vh;
    }
  }
`;

const Bottom = styled.div`
  background-color: #ffffff;
  height: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

class Dialog extends React.PureComponent {
  componentDidMount() {
    if (this.props.open) {
      this.hideNavBar();
    }
  }

  componentDidUpdate(prevProps) {
    const { open } = this.props;

    if (prevProps.open !== open) {
      this.hideNavBar();
      if (open) {
        document.getElementById('app').inert = true;
      } else {
        document.getElementById('app').inert = false;
      }
    }
  }

  hideNavBar = () => {
    const { open } = this.props;
    const navBar = document.getElementById('navBar');

    if (navBar) {
      navBar.classList.toggle('move-down', open);
    }
    document.body.classList.toggle('no-scroll', open);
  };

  render() {
    const { children, closeHidden, onRequestClose, open, maxWidth, bodyOnly } =
      this.props;

    return ReactDOM.createPortal(
      <Container open={open}>
        <Overlay onClick={onRequestClose} open={open} />
        <BodyContainer
          role="dialog"
          aria-modal="true"
          maxWidth={maxWidth}
          open={open}
        >
          {!bodyOnly && (
            <Top>
              {!closeHidden && (
                <button
                  className="close"
                  onClick={onRequestClose}
                  aria-label="modal close button"
                  aria-describedby="close-modal-button-desc-for-screen-reader"
                  type="button"
                >
                  <SVG src={Close} />
                </button>
              )}
            </Top>
          )}
          {/* to fix button freeze */}
          <Body className={bodyOnly ? 'bodyOnly' : ''}>
            {' '}
            {open && children}
          </Body>
          {!bodyOnly && (
            <>
              <Bottom />
              <p
                style={{ visibility: 'hidden' }}
                id="close-modal-button-desc-for-screen-reader"
              >
                Click this button to keep your session and close the modal
              </p>
            </>
          )}
        </BodyContainer>
      </Container>,
      document.body,
    );
  }
}

Dialog.propTypes = {
  children: PropTypes.node,
  closeHidden: PropTypes.bool,
  onRequestClose: PropTypes.func,
  open: PropTypes.bool,
  maxWidth: PropTypes.string,
  bodyOnly: PropTypes.bool,
};

Dialog.defaultProps = {
  closeHidden: false,
  onRequestClose: () => {},
  open: false,
  maxWidth: '511px',
};

const withReducer = injectReducer({ key: 'dialogTriggers', reducer });
export default compose(withReducer)(Dialog);
