/* eslint-disable prettier/prettier */
/*
 * Preferences Messages
 *
 * This contains all the text for the Preferences container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Preferences';
export const matchingScope = 'app.containers.Matching';

export default defineMessages({
  preferences: {
    defaultMessage: 'Preferences',
  },
  deleteData: {
    defaultMessage: 'I would like to request deletion of my personal data',
  },
  personalDetails: {
    defaultMessage: 'Personal Details',
  },
  emergencyContact: {
    defaultMessage: 'Emergency Contact',
  },
  billingDetails: {
    defaultMessage: 'Billing Details',
  },
  modifyBillingDetails: {
    defaultMessage: 'Modify Billing Details',
  },
  benefitsCoverage: {
    defaultMessage: 'Benefits Coverage',
  },
  primaryInsuranceCarrier: {
    defaultMessage: 'Primary Insurance Carrier',
  },
  selectInsuranceCarrier: {
    defaultMessage: 'Select an insurance carrier..',
  },
  unableToVerify: {
    defaultMessage: 'Unable to verify policy details',
  },
  mustMatch: {
    defaultMessage:
      'The GSC policy ID, dependent number, and name must match exactly to what is on file with Green Shield. Please double check your policy information.',
  },
  findInformation: {
    defaultMessage:
      'Find this information on your GSC policy card as shown in the example below.',
  },
  dismissInformation: {
    defaultMessage: 'Dismiss',
  },
  manualPost: {
    defaultMessage:
      'You can still book this session now and submit a claim manually post-session.',
  },
  skipForNow: {
    defaultMessage: 'Skip For Now',
  },
  contactSupport: {
    defaultMessage: 'Contact Support',
  },
  noBenefits: {
    defaultMessage: 'None',
  },
  invalidCoverage: {
    defaultMessage:
      "We currently don't offer direct billing for {selectedCoverage}. Please submit your claims manually.",
  },
  deletePolicy: {
    defaultMessage: 'Delete Policy Information',
  },
  deleteConfirmation: {
    defaultMessage: 'Are you sure you want to delete your policy information?',
  },
  policyId: {
    defaultMessage: 'Policy ID',
  },
  dependantCode: {
    defaultMessage: 'Dependant Code',
  },
  whereCanIFindInformation: {
    defaultMessage: 'Where can I find this information?',
  },
  directBilling: {
    defaultMessage: 'Direct billing',
  },
  directBillingIfSetToOn: {
    defaultMessage:
      'If set to <b>On</b>, direct billing will be active by default. This includes sessions booked on your behalf by your care provider or an Inkblot support member.',
  },
  directBillingYouCanModifyManuallyPerSession: {
    defaultMessage:
      'You can manually change this each time you book a session.',
  },
  errorInsuranceVerification: {
    defaultMessage:
      'We cannot validate your GSC plan at this time. Please check with GSC for your exact information on file or contact the <u>GSC Support Team</u> for further assistance.',
  },
  isPrimaryInsurance: {
    defaultMessage: 'Is this your primary insurance coverage?',
  },
  cancelBenefits: {
    defaultMessage: 'Cancel',
  },
  deleteBenefits: {
    defaultMessage: 'Delete',
  },
  haveBenefits: {
    defaultMessage: 'I have Benefits Coverage',
  },
  removeBenefits: {
    defaultMessage: 'Remove',
  },
  verifyAccount: {
    defaultMessage: 'Verify Account',
  },
  saveAccount: {
    defaultMessage: 'Save',
  },
  modifyBenefitsCoverage: {
    defaultMessage: 'Modify Benefits Coverage',
  },
  updateBenefitsCoverage: {
    defaultMessage: 'Update Changes',
  },
  emailPassword: {
    defaultMessage: 'Email & Password',
  },
  jumpTo: {
    defaultMessage: 'Jump To:',
  },
  chipMyCareTeam: {
    defaultMessage: 'My Care Team',
  },
  chipBilling: {
    defaultMessage: 'Billing',
  },
  chipEmployeeWellness: {
    defaultMessage: 'Sponsored Hours',
  },
  chipInvite: {
    defaultMessage: 'Invite',
  },
  firstName: {
    defaultMessage: 'First Name',
  },
  lastName: {
    defaultMessage: 'Last Name',
  },
  preferredName: {
    defaultMessage: 'Preferred Name',
  },
  dateOfBirth: {
    defaultMessage: 'Date of Birth',
  },
  pronouns: {
    defaultMessage: 'Pronouns',
  },
  gender: {
    defaultMessage: 'Gender Identity',
  },
  phoneNumber: {
    defaultMessage: 'Phone Number',
  },
  timezone: {
    defaultMessage: 'Time Zone',
  },
  address: {
    defaultMessage: 'Address',
  },
  street: {
    defaultMessage: 'Address line 1',
  },
  alternateStreet: {
    defaultMessage: 'Address line 2',
  },
  cityTown: {
    defaultMessage: 'City/Town',
  },
  province: {
    defaultMessage: 'Province/Territory',
  },
  state: {
    defaultMessage: 'State',
  },
  zone: {
    defaultMessage: 'Zone',
  },
  zipCode: {
    defaultMessage: 'Zip Code',
  },
  country: {
    defaultMessage: 'Country',
  },
  postalCode: {
    defaultMessage: 'Postal Code',
  },
  updatePicture: {
    defaultMessage: 'Update Picture',
  },
  updateChanges: {
    defaultMessage: 'Update Changes',
  },
  updateEmail: {
    defaultMessage: 'Update Email',
  },
  enterCurrentEmail: {
    defaultMessage: 'Enter Current Email',
  },
  enterNewEmail: {
    defaultMessage: 'Enter New Email',
  },
  confirmNewEmail: {
    defaultMessage: 'Confirm New Email',
  },
  enterCurrentPassword: {
    defaultMessage: 'Enter Current Password',
  },
  enterNewPassword: {
    defaultMessage: 'Enter New Password',
  },
  confirmNewPassword: {
    defaultMessage: 'Confirm New Password',
  },
  updatePassword: {
    defaultMessage: 'Update Password',
  },
  profilePicture: {
    defaultMessage: 'Profile Picture',
  },
  reminders: {
    defaultMessage: 'Reminders',
  },
  smsReminders: {
    defaultMessage:
      'I would like to receive an SMS reminder at 8am on the day of my session',
  },
  email: {
    defaultMessage: 'Email',
  },
  password: {
    defaultMessage: 'Password',
  },
  fullName: {
    defaultMessage: 'Full Name',
  },
  relationship: {
    defaultMessage: 'Relationship',
  },
  phoneNumberEmergency: {
    defaultMessage: 'Phone Number',
  },
  cardNumber: {
    defaultMessage: 'Card Number',
  },
  noCardFound: {
    defaultMessage: 'No card found',
  },
  cardSave: {
    defaultMessage: 'Save Card',
  },
  employeeWellness: {
    defaultMessage: 'Sponsored Hours',
  },
  remainingCredits: {
    defaultMessage: 'Remaining Credits',
  },
  reimburseNoCredits: {
    defaultMessage:
      'Cost of services may be reimbursable by your benefit health plan.',
  },
  creditCount: {
    defaultMessage: '{credits} credits ({minutes} minutes)',
  },
  creditsExpire: {
    defaultMessage:
      'When your credits expire, you will be billed for your following sessions until the credits renew',
  },
  atbCreditsExpire: {
    defaultMessage:
      "You'll notice your total credits are shown above. When your credits expire, you can process any further sessions through your ATB Flex Benefit Plan until your credits renew. The cost of counselling is reimbursable under our Flex Benefit Plan.",
  },
  dependants: {
    defaultMessage: 'Dependants',
  },
  eligableDependantInvite: {
    defaultMessage: 'You are eligible to invite {count} dependant',
  },
  inviteDependant: {
    defaultMessage: 'Invite Dependant',
  },
  inviteSomeoneToInkblot: {
    defaultMessage: 'Invite Someone to Inkblot',
  },
  giftOfFeelingBetter: {
    defaultMessage:
      'Share the gift of feeling better {br}{br}Use this form to invite a friend or family member to Inkblot and {thirtyMinutes}',
  },
  thirtyMinutes: {
    defaultMessage: 'get an extra 30 minutes on us.',
  },
  maxAmountOfDependants: {
    defaultMessage: 'Max amount of dependants',
  },
  yourDependantsInfo: {
    defaultMessage: 'Your dependants information',
  },
  relationshipToYou: {
    defaultMessage: 'Relationship to you',
  },
  sendInvite: {
    defaultMessage: 'Send Invite',
  },
  crisisServices: {
    defaultMessage:
      'For crisis support, please call toll-free: <b>1-855-933-0103</b>',
  },
  pdTitle: {
    defaultMessage: 'Parent / Guardian Details',
  },
  pdSubTitle: {
    defaultMessage: 'This information is needed for safety and consent reasons',
  },
  pdFormLabel: {
    defaultMessage: 'Parent / Guardian',
  },
  pdFirstNameLabel: {
    defaultMessage: 'First Name',
  },
  pdLastNameLabel: {
    defaultMessage: 'Last Name',
  },
  pdRelationshipLabel: {
    defaultMessage: 'Relationship',
  },
  pdEmailLabel: {
    defaultMessage: 'Email Address',
  },
  pdPhoneNumberLabel: {
    defaultMessage: 'Phone Number',
  },
  pdAlternatePhoneNumberLabel: {
    defaultMessage: 'Alternate Phone Number',
  },
  pdAddressCheckBox: {
    defaultMessage: 'Address same as person seeking therapy',
  },
  pdAdditionalParentButton: {
    defaultMessage: 'Add additional parents / guardians',
  },
  pdRemovalParentButton: {
    defaultMessage: 'Remove parent / guardian',
  },
  pdAddress: {
    defaultMessage: 'Address 1',
  },
  pdAlternateStreet: {
    defaultMessage: 'Address 2',
  },
  pdCityTown: {
    defaultMessage: 'City/Town',
  },
  stateProvince: {
    defaultMessage: 'Province/Territory/State',
  },
  pdPostalCode: {
    defaultMessage: 'Postal/Zip Code',
  },
  pdCountry: {
    defaultMessage: 'Country',
  },
  pdZone: {
    defaultMessage: 'Zone',
  },
  aptNumber: {
    defaultMessage: 'Apartment, suite, unit, etc.',
  },
  streetAddress: {
    defaultMessage: 'Street address',
  },
  verifyPhoneNumber: {
    defaultMessage: 'Verify phone number',
  },
  policyIdMustBeAlphanumeric: {
    defaultMessage: 'Your Policy ID should be only letters and numbers.',
  },
  policyIdProvidedMightIncludeDependantCode: {
    defaultMessage:
      'If your insurance ID card shows an ID in the form XXXXX-YY, then XXXXX is your Policy ID and YY is your dependant code.',
  },
  allLettersMustBeCapitalized: {
    defaultMessage: 'Any letters in your policy ID should be capitalized.',
  },
  dependantCodeInvalidFormat: {
    defaultMessage:
      'Your dependant code should be two characters. If you are the primary plan member, your code is 00.',
  },
  invalidPostalCode: {
    defaultMessage: 'Invalid postal code',
  },
  invalidZIPCode: {
    defaultMessage: 'Invalid ZIP code',
  },
  male: {
    defaultMessage: 'Man',
  },
  female: {
    defaultMessage: 'Woman',
  },
  nonBinary: {
    defaultMessage: 'Gender Non-Binary or Expansive',
  },
  transgender: {
    defaultMessage: 'Transgender',
  },
  other: {
    defaultMessage: 'Other, none of the above',
  },
  specifyGender: {
    defaultMessage: 'Specify Gender',
  },
  notSpecified: {
    defaultMessage: 'Prefer not to say',
  },
  whyCantIChange: {
    defaultMessage: 'Why Can’t I change Some of my Details?',
  },
  changesToCertainFields: {
    defaultMessage:
      'Changes to certain fields must be made through your plan administrator',
  },
});
